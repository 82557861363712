import React from "react";
import style from "./banner2.module.css";
import nu3 from "../../../assets/nu3.png";


const Banner2 = () => {
  return (
    <div className={style.main}>
      <div className={style.card}>
        <div className={style.left}>
          <p className={style.haiding}>nu is For Everyone!</p>
          <p className={style.detail}>
          Whether you're earning rewards for social engagement or conducting peer-to-peer transactions, NRU offers a versatile solution for your digital needs. Experience the future of decentralized finance with nu.
          </p>
          <p className={style.detail2}>Looking to collaborate with nu?</p>
          <div className={style.action}>
            <button
              onClick={() => {
                window.open("https://t.me/contact_nuconsortium");
              }}
              className={style.action1}
            >
              Contact Us
            </button>
          </div>
        </div>
        <div className={style.right}>
          <div className={style.inner}>
            <div className={style.image3}>
              <img alt="img" src={nu3} className={style.img3} />
            </div>
          </div>

          <p className={style.bottomT}>Join the future of Blockchain.</p>
        </div>
      </div>
      <div className={style.coloredline}></div>
    </div>
  );
};

export default Banner2;
