import React from "react";
import style from "./banner.module.css";
import nu2 from "../../../assets/nu2.png";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();

  return (
    <div className={style.main}>
      <div className={style.card}>
        <div className={style.left}>
          <img src={nu2} alt="img" className={style.img2} />
        </div>
        <div className={style.right}>
          <p className={style.rHiding}>
          The nu Consortium Is Community-Operated, Empowering You To Control Your Experience
          </p>
          <p className={style.rDetail}>
          The nu consortium comprises two key entities: nuBlock, a blockchain-based social network where community governance thrives, and nuPayments, an invoicing and payment management platform facilitating seamless cross-border transactions for individuals and businesses alike.
          </p>
          <div className={style.action}>
            <button onClick={()=>{
              navigate('/nupayments')
            }} className={style.action1}>
              nuPayments
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
