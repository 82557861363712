import React, { useEffect } from "react";
import style from "./nuBlock.module.css";
import mobiles from "../../assets/nuBlock.png";
import Banner2 from "../../components/nuBlock/banner2/Banner2";
import Banner from "../../components/nuBlock/banner/Banner";
import ios  from "../../assets/ios.png";
import android  from "../../assets/android.png";
import DownloadButtons from "../../components/common/DownloadButtons/DownloadButtons";


const NuBlock = () => {

  useEffect(() => {
      window.scrollTo({
          top: 0,
          behavior: 'smooth'
      });
  }, []);

  return (
    <div className={style.main}>
      <div className={style.card}>
        <div className={style.left}>
          <p className={style.haiding}>
            Socialize, Discover, Earn Rewards, Send Cross-Border Payments on nuBlock
          </p>
          <p className={style.detail}>
            nu is a consortium at the intersection of social engagement and
            financial innovation within the Blockchain and AI ecosystemdsds
          </p>

          <div className={style.action}>
            <DownloadButtons />
          </div>
        </div>
        <div className={style.right}>
          <div className={style.image2}>
            <img src={mobiles} alt="img" className={style.img2} />
          </div>
        </div>
      </div>
      <Banner />
      <Banner2 />
    </div>
  );
};

export default NuBlock;
