import React, { useEffect } from "react";
import { Routes, Route, useNavigate, Router } from "react-router-dom";
import NuConsortiumHome from "./pages/nuConsortium/NuConsortiumHome";
import Layout from "./components/layout/Layout";
import NuPayment from "./pages/nuPayment/NuPayment";
import NuBlock from "./pages/nuBlock/NuBlock";
import Nru from "./pages/nru/Nru";

const App = () => {

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<NuConsortiumHome />} />
        <Route path="/nupayments" element={<NuPayment />} />
        <Route path="/nublock" element={<NuBlock />} />
        <Route path="/nru" element={<Nru />} />
        {/* <Route path="/whitepaper" element={<Whitepaper /> } /> */}
      </Route>
    </Routes>
  );
};

export default App;
