import React from "react";
import style from "./banner.module.css";
import nu1 from "../../../assets/nu1.png";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();

  return (
    <div className={style.main}>
      <div className={style.card}>
        <div className={style.left}>
          <img src={nu1} alt="img" className={style.img2} />
        </div>
        <div className={style.right}>
          <p className={style.rHiding}>
          Experience The Block. Earn Rewards For Socializing and Process Global Payments
          </p>
          <p className={style.rDetail}>
          Discover a digital hub where social interactions are rewarded, and global payments are seamlessly processed. Join us to explore a vibrant community-driven platform that combines social networking with efficient financial transactions. Experience the future of connectivity and commerce, all in one place.
          </p>
          <div className={style.action}>
            <button onClick={()=>{
              navigate('/nupayments')
            }} className={style.action1}>
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
