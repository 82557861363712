import React, { useEffect } from "react";
import style from "./nuConsortiumHome.module.css";
import nu1 from "../../assets/nu1.png";
import Banner2 from "../../components/nuConsortium/banner2/Banner2";
import Banner from "../../components/nuConsortium/banner/Banner";

const NuConsortiumHome = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className={style.main}>
      <div className={style.card}>
        <div className={style.left}>
          <p className={style.haiding}>
            nu is The Next Gen Blockchain Super dApp
          </p>
          <p className={style.detail}>
            nu is a consortium at the intersection of social engagement and
            financial innovation within the Blockchain and AI ecosystem
          </p>
          <p className={style.detail2}>
            Want to earn NRU? Join our Beta Testing Community.
          </p>
          <div className={style.action}>
            <button
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/1oVish7EdNt4l6AsPco64xcpK7LxQjy01oKj6KJMy6vQ/viewform?edit_requested=true"
                )
              }
              className={style.action1}
            >
              Register Today
            </button>
          </div>
        </div>
        <div className={style.right}>
          <div className={style.image2}>
            <img src={nu1} alt="img" className={style.img2} />
          </div>
        </div>
      </div>
      <Banner />
      <Banner2 />
    </div>
  );
};

export default NuConsortiumHome;
