import React , {useEffect} from "react";
import style from "./nru.module.css";
import nu from "../../assets/nru.png";
import Banner2 from "../../components/nru/banner2/Banner2";
import Banner from "../../components/nru/banner/Banner";

const Nru = () => {

  useEffect(() => {
    window.scroll(0, 0); 
}, []);

  return (
    <div className={style.main}>
      <div className={style.card}>
        <div className={style.left}>
          <p className={style.haiding}>NRU Powers Social and Retail Payments</p>
          <p className={style.detail}>
          NRU is the native digital asset of nu. Join The revolution To streamline Digital Assets
          </p>
          <div className={style.action}>
            {/* <button onClick={()=>window.open('https://docs.google.com/forms/d/1978tNCeiSVkX7VztPbzIkjtwq0vXHpiwfDSaGZNXN0c/edit','_blank')} className={style.action1}>Whitelist</button> */}
            {/* <a className={style.action1} href="https://www.pinksale.finance/launchpad/polygon/0x241592eb04F80E6aEf2aC784a359FC3A6651C7bc" target="_BLANK">Buy Now</a> */}
          </div>
        </div>
        <div className={style.right}>
        <div className={style.image2} >
         <img  src={nu} alt='img' className={style.img2} />
         </div>
          {/* <div className={style.inner}>
           <div className={style.image3} >
           <img alt="img" src={nu} className={style.img3} />
           </div>
           <div className={style.image1} >
           <img alt="img" src={nu} className={style.img1} />
           </div>
          </div> */}

        </div>
      </div>
     <Banner/>
     <Banner2/>
    </div>
  );
};

export default Nru;
