import React from "react";
import style from "./banner2.module.css";
import currency1 from "../../../assets/currency1.png";
import currency2 from "../.././../assets/currency2.png";
import currency3 from "../../../assets/nu3.png";

const Banner2 = () => {
  return (
    <div className={style.main}>
      <div className={style.card}>
        <div className={style.left}>
          <p className={style.haiding}>Our Partners</p>
          <p className={style.detail}>
            We have partnered with top Blockchain networks to streamline social
            rewards for nu community members, faster and efficient payment
            solutions to forward a more connected network without barriers.
          </p>
          <p className={style.detail2}>Looking to collaborate with nu?</p>
          <div className={style.action}>
            <button
              onClick={() => {
                window.open("https://t.me/contact_nuconsortium");
              }}
              className={style.action1}
            >
              Contact Us
            </button>
          </div>
        </div>
        <div className={style.right}>
          <div className={style.inner}>
            <div className={style.image1}>
              <img alt="img" src={currency1} className={style.img1} />
            </div>
            <div className={style.image3}>
              <img alt="img" src={currency3} className={style.img3} />
            </div>
          </div>

          <div className={style.image2}>
            <img src={currency2} alt="img" className={style.img2} />
          </div>
          <p className={style.bottomT}>Join the future of Blockchain.</p>
        </div>
      </div>
      <div className={style.coloredline}></div>
    </div>
  );
};

export default Banner2;
