import React from "react";
import style from "./banner2.module.css";
import currency1 from "../../../assets/currency1.png";
import currency2 from "../.././../assets/currency2.png";
import currency3 from "../../../assets/nu3.png";

const Banner2 = () => {
  const navigateToLogin = () => {
    const local = "http://localhost:3000/auth-signup.html";
    const live = "https://www.auth.nuconsortium.xyz/auth-signup.html";
    window.open(`${live}?type=individual`, "_blank");
  };

  return (
    <div className={style.main}>
      <div className={style.card}>
        <div className={style.left}>
          <p className={style.haiding}>Roadmap</p>
          <div className={style.pinkDivLeft}>
            <p className={style.itemList}>
              2024 <br />
              nu social dApp Beta launch
              <br />
              Development of user-friendly interfaces.
              <br />
              Testing and user feedback.
              <br />
              Smart Contract Integration
              <br />
              Comprehensive security audits
              <br />
              Web Social Expansion and Blockchain.
              <br />
              Integration with external platforms. <br />
              Integrate gaming through partnerships
              <br />
              API Business Solutions
              <br />
              Continue portal development - Bridge Partnerships
            </p>
          </div>
        </div>
        <div className={style.right}>
          <div className={style.pinkDivRight}>
            <p className={style.itemList}>
              2025 <br />
              POS Terminals <br />
              Generative AI Integration <br />
              Development Global Partnerships <br />
              Regulatory and Compliance <br />
              Banking Partnerships <br />
              Events <br />
              Conventions - Speaker <br />
              Continue Building <br />
             
            </p>
          </div>
        </div>
      </div>
      <div className={style.coloredline}></div>
    </div>
  );
};

export default Banner2;
